import React from "react";
import vic1 from "../../assets/img/vic1.jpg";
import vic2 from "../../assets/img/vic2.webp";
import vic3 from "../../assets/img/vic3.webp";
import vic4 from "../../assets/img/vic4.webp";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const VictorySecret = () => {
  const victoriaData = [
    {
      id: 0,
      desc: "Tease Crème Cloud",
      link: "134-tease-creme-cloud",
      img: vic3,
    },
    {
      id: 1,
      desc: "VS Him Platinum",
      link: "106-vs-him-platinum",
      img: vic2,
    },
    {
      id: 2,
      desc: "Tease Candy Noir",
      link: "105-tease-candy-noir",
      img: vic1,
    },
    {
      id: 3,
      desc: "Bombshell Seduction Eau de Parfum",
      link: "94-bombshell-seduction-eau-de-parfum",
      img: vic4,
    },
  ];

  return (
    <section className="victoria my-3">
      <div className="container-fluid">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          breakpoints={{
            640: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 30 },
            1024: { slidesPerView: 4, spaceBetween: 40 },
          }}
          autoplay={{ delay: 1800, disableOnInteraction: false }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {victoriaData.map((i) => (
            <SwiperSlide key={i.id}>
              <NavLink to={`/${i.link}`}>
                <div className="victoriaBox">
                  <img src={i.img} alt={i.desc} className="img-fluid" />
                  <div className="outline">
                    <div className="outlineText">
                      <h3>Victoria Secret</h3>
                      <p>{i.desc}</p>
                    </div>
                  </div>
                </div>
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default VictorySecret;
