import React, { useContext, useEffect } from "react";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";
import { NavLink } from "react-router-dom";

const BreadCrumb = ({ title }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const nullQuery = localStorage.getItem("nullQuery");
  useEffect(() => {}, [nullQuery]);
  return (
    <>
      <nav className="breadcrumb-section theme1 bg-lighten2 pt-40 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h2
                  className="title pb-4 text-dark"
                  style={{ fontFamily: "Montserrat", fontWeight: "370",textTransform:"uppercase" }}
                >
                  {title}
                </h2>
              </div>
            </div>
            <div className="col-12">
              {nullQuery == "null" && (
                <h5 className="breadcrumb-item active text-center" style={{lineHeight:"normal"}}>
                  {t.notResultDesc}
                </h5>
              )}
              <ol
                className={`breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center ${
                  nullQuery == "null" && "d-none"
                }`}
              >
                <li className="breadcrumb-item">
                  <NavLink to="/">{t.home}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page" style={{textTransform:"uppercase"}}>
                  {title}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default BreadCrumb;
