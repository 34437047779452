import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { addItem } from "../../utils/addtocart";
import { addWishlist } from "../../utils/addtowishlist";
import ModalView from "../cart/ModalView";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";

import { Toaster, toast } from "sonner";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
import man from "../../assets/img/man.jpg";
import woman from "../../assets/img/woman.jpg";

import manicon from "../../assets/img/manicon.png";
import womanicon from "../../assets/img/womanicon.jpg";
import unisexicon from "../../assets/img/unisexicon.png";
import Slider from "react-infinite-logo-slider";

const NewProducts = ({ setSelectedData }) => {
  const [localWish, setLocalWish] = useState(
    () => JSON.parse(localStorage.getItem("wishlist")) || []
  );
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { products, brand } = useContext(ApiLinkContext);
  const shouldEnableNavigation = () => {
    return visibleProductsCount > 0 && visibleProductsCount > 4;
  };
  const visibleProductsCount = products.filter((item) => item.dis > 0).length;

  const calculateDis = (price,dis) =>{
    return "-"+ Math.round(((price - dis) / price) * 100);
  }

  return (
    <>
      <section className="theme1 bg-white pb-80 py-5">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="section-title text-center">
                <h2 className="title pb-3">{t.newProducts}</h2>
              </div>
            </div>
            <Swiper
              slidesPerView={1}
              spaceBetween={3}
              navigation={shouldEnableNavigation()}
              loop={true}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              autoplay={{
                delay: 1800,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
            >
              {products.slice(-20).map((i) => {
                const active = localWish.find((item) => item.id == i.id);
                const brandName = brand.find((item) => item.id == i.brand);
                return (
                  <SwiperSlide>
                    <div className="col-lg-12 col-md-12 col-sm-6 col-12 mb-5">
                      <div className="product-slider-init theme1 slick-nav">
                        <div className="slider-item">
                          <div className="card product-card">
                            <div className="card-body p-0">
                              <div className="media flex-column">
                                <div className="product-thumbnail position-relative">
                                  <span
                                    className="badge top-right"
                                    style={{ left: "0" }}
                                  >
                                    <img
                                      src={`${
                                        i.sex == "1"
                                          ? manicon
                                          : i.sex == "2"
                                          ? womanicon
                                          : unisexicon
                                      }`}
                                      alt=""
                                      className="img-fluid"
                                      style={{
                                        position: "absolute",
                                        zIndex: "1",
                                        right: "0",
                                        width: i.sex == "1" ? "20px" : "25px",
                                      }}
                                    />
                                  </span>
                                  <p
                                    id="calculateDis"
                                    className={`badge  my-2 ms-2 py-3   text-center ${
                                      i.dis > 0 ? "d-block" : "d-none"
                                    }`}
                                  >
                                  
                                      {calculateDis(
                                        i.price,
                                        i.dis
                                      )}
                                      %
                                  
                                  </p>
                                  <NavLink
                                    to={`/${slugify(
                                      `${i.id}-${i.name}`
                                    ).toLowerCase()}`}
                                  >
                                    {i.img && i.img.length > 0 && (
                                      <>
                                        <img
                                          src={i.img[1]}
                                          alt="thumbnail"
                                          className="first-img img-fluid"
                                          style={{ width: "100%" }}
                                        />
                                        <img
                                          src={i.img[0]}
                                          alt="thumbnail"
                                          className="second-img img-fluid"
                                          style={{ width: "100%" }}
                                        />
                                      </>
                                    )}
                                  </NavLink>

                                  <ul className="actions d-flex justify-content-center">
                                    <li>
                                      <a
                                        className={`action ${
                                          active ? "activeWish" : ""
                                        }`}
                                        onClick={() => addWishlist(i.id)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span
                                          data-bs-toggle="tooltip"
                                          data-placement="bottom"
                                          title="add to wishlist"
                                          className="icon-heart"
                                        ></span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="action"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#quick-view"
                                        onClick={() => {
                                          setSelectedData(i);
                                        }}
                                      >
                                        <span
                                          data-bs-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Quick view"
                                          className="icon-magnifier"
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="media-body">
                                  <div className="product-desc">
                                    <h3 className="title">
                                      <a>
                                        {brandName &&
                                          brandName.bName
                                            .charAt(0)
                                            .toUpperCase() +
                                            brandName.bName
                                              .slice(1)
                                              .toLowerCase()}{" "}
                                        {i.name} {i.volume}
                                        {t.ml}
                                      </a>
                                    </h3>
                                    <div className="star-rating">
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star de-selected" />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="product-price">
                                        <del
                                          className="del"
                                          style={{
                                            textDecoration: `${
                                              i.dis > 0
                                                ? "line-through"
                                                : "none"
                                            }`,
                                          }}
                                        >
                                          {i.price} ₼
                                        </del>
                                        <span
                                          className={`onsale ms-2 ${
                                            i.dis > 0 ? "" : "d-none"
                                          }`}
                                        >
                                          {i.dis} ₼
                                        </span>
                                      </p>
                                      <button
                                        className="pro-btn "
                                        onClick={() => {
                                          addItem(i.id);
                                          toast.success(
                                            `${t.cart + ":"} ${i.name}-${
                                              i.volume + "ML"
                                            }`,
                                            {
                                              duration: 700,
                                              style: {
                                                backgroundColor: "#00C2CB",
                                                color: "#ffffff",
                                                fontFamily: "Montserrat",
                                                border: "none",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <i className="icon-basket" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewProducts;
