import React from 'react'

const Parallax = () => {
  return (
    <>
     <div className="parallax my-5"></div>
    </>
  )
}

export default Parallax
