import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";

const Cookie = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
  const [close, setClose] = useState(false);

  // Hər dəfə səhifə yüklənəndə `localStorage`-i yoxlayırıq
  useEffect(() => {
    const cookieAccepted = localStorage.getItem("cookieAccepted");
    const cookieExpiry = localStorage.getItem("cookieExpiry");

    // Əgər cookie qəbul edilibsə və müddət keçməyibsə, section-u gizlədirik
    if (cookieAccepted && Date.now() < cookieExpiry) {
      setClose(true); // Əgər seçim edilibsə, pop-up artıq göstərilmir
    }
  }, []);

  const handleAccept = () => {
    // "Qəbul et" klik edildikdə `localStorage`-ə məlumat yazılır və 1 həftəlik müddət təyin edilir
    localStorage.setItem("cookieAccepted", "true");
    localStorage.setItem("cookieExpiry", Date.now() + 7 * 24 * 60 * 60 * 1000); // 1 həftəlik müddət
    setClose(true); // Pop-up qapanır
  };

  const handleReject = () => {
    // "İmtina et" klik edildikdə `localStorage`-ə məlumat yazılır və 1 həftəlik müddət təyin edilir
    localStorage.setItem("cookieAccepted", "false");
    localStorage.setItem("cookieExpiry", Date.now() + 7 * 24 * 60 * 60 * 1000); // 1 həftəlik müddət
    setClose(true); // Pop-up qapanır
  };

  // Əgər istifadəçi "Qəbul et" və ya "İmtina et" düyməsini basıbsa, section göstərilməsin
  if (close) return null;

  return (
    <section
      className="text-white"
      id="cookieSec"
      style={{
        position: "fixed",
        bottom: "0",
        zIndex: "99",
        width: "100%",
        padding:"5px 0",
        background: "rgb(1, 148, 161)",
      }}
    >
      <div className="container-fluid">
        <div className="row mx-5 justify-content-center">
          <div className="col-lg-10 col-md-10 col-sm-12 col-12">
            <p dangerouslySetInnerHTML={{__html :t.cookieDesc}} id="cookieDesc"/>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-12">
            <div className="d-flex justify-content-center">
              <button
                className="p-3"
                id="cookieBtn"
                style={{ border: "1px solid #fff", borderRadius: "10px" }}
                onClick={handleAccept}
              >
                {t.accept}
              </button>
              <button
                className="p-3 mx-2"
                id="cookieBtn"
                onClick={handleReject}
                style={{ border: "1px solid #fff", borderRadius: "10px" }}
              >
                {t.reject}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cookie;
