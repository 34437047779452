import React from "react";
import xerjoff from "../../assets/img/xerjoff.webp";
import demarly from "../../assets/img/demarly.webp";
import mancera from "../../assets/img/mancera.webp";
import montale from "../../assets/img/montale.webp";
import { NavLink } from "react-router-dom";
const HomeBrand = () => {
    const mockiBrandData = [
        {id:1,title:"xerjoff",img:xerjoff},
        {id:10,title:"montale",img:montale},
        {id:11,title:"mancera",img:mancera},
        {id:50,title:"parfums-de-marly",img:demarly},
    ]
  return (
    <>
      <section className="homeBrand">
        <div className="container">
          <div className="row g-3">
            {mockiBrandData.map((item) => {
              return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                      <NavLink to={`/perfume/${item.id}-${item.title}`}>
                    <div className="homeBrandImg">
                      <img src={item.img} alt="" className="img-fluid" />
                    </div>
                </NavLink>
                  </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBrand;
