import React, { useContext } from "react";
import Header from "./Header";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import banner from "../assets/img/banner.jpg";
import { NavLink } from "react-router-dom";
import Countdown from "react-countdown";
const HeaderTop = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };
  const targetDate = new Date(2025, 0, 6);
  return (
    <>
      {/* <div className="disHeader">
        <div className="container">
          <div className="row justify-content-center mx-1">
            <div className="col-3 "  id="facebook" style={{padding:"0"}}>
              <div className="social">
                <a
                  href="https://www.facebook.com/MyParfumes.azGroup/?_rdr"
                  target="_blank"
                  id="facebook"
                >
                  <span className="icon-social-facebook " />
                </a>
                <a
                  href="https://www.instagram.com/myperfume.az/?igsh=cGFuZ2JmbHZ4amxj"
                  target="_blank"
                  className="mx-3"
                  id="instagram"
                >
                  <span className="icon-social-instagram" />
                </a>
                <a
                  href="https://wa.me/994508055511"
                  target="_blank"
                  className="text-white"
                  
                >
                  <i className="icon-call-out me-1" /> +994 50 805 55 11
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-12 text-center disCol" style={{padding:"0"}}>
            <NavLink to="/discount" className="disTitle">{t.disTitle} </NavLink>
          </div>

          <div className="col-lg-3 col-sm-12 col-12 text-center" style={{padding:"0"}}>
            <span className="timeout">
              <Countdown date={targetDate} />
            </span>
          </div>
          </div>

        </div>
      </div> */}
      <div>
        <div
          className="header-top theme1 py-15"
          style={{ background: "#000" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6 order-last order-sm-first">
                <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                  <div className="social-network2">
                    <ul className="d-flex">
                      <li>
                        <a
                          href="https://www.facebook.com/MyParfumes.azGroup/?_rdr"
                          target="_blank"
                        >
                          <span className="icon-social-facebook" />
                        </a>
                      </li>
                      <li className="mr-0">
                        <a
                          href="https://www.instagram.com/myperfume.az/?igsh=cGFuZ2JmbHZ4amxj"
                          target="_blank"
                        >
                          <span className="icon-social-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="media static-media media1 ms-4 d-flex align-items-center ">
                    <div className="media-body">
                      <div className="phone">
                        <a
                          href="https://wa.me/994508055511"
                          target="_blank"
                          className="text-white"
                        >
                          <i className="icon-call-out me-1" /> +994 50 805 55 11
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 d-flex justify-content-between flex-row-reverse">
                <nav className="navbar-top pb-2 pb-sm-0 position-relative">
                  <ul className="d-flex justify-content-center justify-content-md-end align-items-center">
                    <select
                      name=""
                      id="selectLang"
                      value={language}
                      onChange={handleLanguageChange}
                    >
                      <option value="az">AZ</option>
                      <option value="en">EN</option>
                      <option value="ru">RU</option>
                    </select>
                  </ul>
                </nav>
                <div className="media static-media d-none" id="mediaBody">
                  <div className="media-body">
                    <div className="phone">
                      <a href="tel:+994508055511" className="text-white">
                        <i className="icon-call-out me-1" /> +994 50 805 55 11
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Header />
      </div>
    </>
  );
};

export default HeaderTop;
