import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import { Link, NavLink } from "react-router-dom";
import { ApiLinkContext } from "../context/ApiLinkContext";
import axios from "axios";
import slugify from "react-slugify";
const Footer = () => {
  const { ApiLink } = useContext(ApiLinkContext);
  const date = new Date();
  const currentYear = date.getFullYear();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [sex, setSex] = useState([]);
  useEffect(() => {
    axios(`${ApiLink}/products.php`)
      .then((res) => {
        setSex(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const uniqueSex = [...new Set(sex.map((item) => item.sex))].sort(
    (a, b) => a - b
  );

  return (
    <>
      <footer className="bg-dark text-white theme1 position-relative mt-5">
        <div className="footer-bottom pt-80 pb-30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 col-lg-4 mb-30">
                <div className="footer-widget mx-w-400">
                  <p className="text text-white mb-30">
                    {t.address} : {t.contactAddress}
                    <br />
                    {t.email}:{" "}
                    <a href="mailto:info@myperfume.az">info@myperfume.az</a>
                    <br />
                    {t.phone}:{" "}
                    <a href="https://wa.me/994508055511" target="_blank">
                      +994 50 805 55 11
                    </a>
                    <br />
                    <a
                  href="https://www.facebook.com/MyParfumes.azGroup/?_rdr"
                  target="_blank"
                  id="facebook"
                >
                  <span className="icon-social-facebook " />
                </a>
                <a
                  href="https://www.instagram.com/myperfume.az/?igsh=cGFuZ2JmbHZ4amxj"
                  target="_blank"
                  className="mx-3"
                  id="instagram"
                >
                  <span className="icon-social-instagram" />
                </a>
                  </p>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-2 mb-30">
                <div className="footer-widget">
                  <ul className="footer-menu">
                    {uniqueSex.map((item, index) => {
                      const changeSex =
                        item === "1" ? t.man : item === "2" ? t.woman : "";
                      const slug = slugify(`${changeSex}`);

                      return (
                        <li key={index}>
                          <NavLink
                              to={`/perfume/${`${item}-${
                                item === "1"
                                  ? "perfumes-for-man"
                                  : item === "2"
                                  ? "perfumes-for-women"
                                  : language === "ru" && item === "1"
                                  ? "perfumes-for-man"
                                  : language === "ru" && item === "2"
                                  ? "perfumes-for-women"
                                  : ""
                              }`}`}
                            style={{ fontSize: "18px" }}
                            className="text-white"
                          >
                            {changeSex}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-2 mb-30">
                <div className="footer-widget">
                  <ul className="footer-menu">
                    <li>
                      <Link
                        to="/about"
                        onClick={() => window.scrollTo({ top: 0 })}
                        style={{ fontSize: "18px" }}
                        className="text-white"
                      >
                        {t.about2}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        onClick={() => window.scrollTo({ top: 0 })}
                        style={{ fontSize: "18px" }}
                        className="text-white"
                      >
                        {t.contact1}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-lg-3 mb-30">
                <div className="footer-widget termsMobile">
                  <ul className="footer-menu">
                    <li>
                      <NavLink
                        to="/termsofuse"
                        className="text-white"
                        style={{ fontSize: "18px" }}
                      >
                        {t.terms}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/privacypolicy"
                        className="text-white"
                        style={{ fontSize: "18px" }}
                      >
                        {t.terms1}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="coppy-right bg-dark py-15">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12 order-last order-md-first">
                <div className="text-md-start text-center mt-3 mt-md-0">
                  <p className="text-center">
                    © Copyright {currentYear}{" "}
                    <a href="https://myperfume.az" target="_blank">
                      MyPerfume.az
                    </a>
                    . {t.copyright}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
