import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ModalWish from "../pages/home/ModalWish";
import ModalCart from "../pages/home/ModalCart";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";
import logo from "../assets/img/MYPERFUME.png";
import logomobile from "../assets/img/parfumee.png";
import axios from "axios";
import ApiLinkContext from "../context/ApiLinkContext";
import slugify from "react-slugify";
const Header = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const { products, brand, tag, accord } = useContext(ApiLinkContext);
  const [openWish, setOpenWish] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [active, setActive] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);

  const handleOpenWish = () => {
    setOpenWish((prevState) => !prevState);
  };
  const handleOpenCart = () => {
    setOpenCart((prevState) => !prevState);
  };
  const [cart, setCart] = useState([]);
  const [wish, setWish] = useState([]);
  const updateWish = () => {
    const localWish = JSON.parse(localStorage.getItem("wishlist")) || [];
    setWish(localWish);
  };

  const updateCart = () => {
    const localCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(localCart);
  };

  useEffect(() => {
    updateWish();
    window.addEventListener("wishUpdated", updateWish);
    return () => {
      window.removeEventListener("wishUpdated", updateWish);
    };
  }, []);

  useEffect(() => {
    updateCart();
    window.addEventListener("cartUpdated", updateCart);
    return () => {
      window.removeEventListener("cartUpdated", updateCart);
    };
  }, []);

  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenModal(false)
    const result = products.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    const brandResult = brand.filter((item) =>
      item.bName.toLowerCase().includes(query.toLowerCase())
    );

    const accordResult = accord.filter((item) =>
      item.aName.toLowerCase().includes(query.toLowerCase())
    );

    const tagResult = tag.filter((item) =>
      item.tName.toLowerCase().includes(query.toLowerCase())
    );

    const data =
      result.length > 0
        ? { type: "product", items: result }
        : brandResult.length > 0
        ? { type: "brand", items: brandResult }
        : accordResult.length > 0
        ? { type: "accord", items: accordResult }
        : tagResult.length > 0
        ? { type: "tag", items: tagResult }
        : null;
    navigate("/perfume", {
      state: { data, query },
    });
  };

  // const filteredProducts = product.filter(
  //   (item) => item && (item.sex === "1" || item.sex === "2" || item.sex === "3")
  // );

  // const uniqueSexNames = [
  //   ...new Set(
  //     filteredProducts.map((item) => {
  //       if (item.sex === "1") return `${t.man}`;
  //       if (item.sex === "2") return `${t.woman}`;
  //       if (item.sex === "3") return `${t.unisex}`;
  //     })
  //   ),
  // ];
  const sortedBrands = brand
    .filter((item) => products.some((i) => i.brand == item.id))
    .sort((a, b) => a.bName.localeCompare(b.bName));

  // Brendləri hərf üzrə qruplaşdırırıq
  const groupedBrands = sortedBrands.reduce((acc, item) => {
    const firstLetter = item.bName.charAt(0).toUpperCase(); // Hərfi böyük etmək
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };
  return (
    <>
      <div id="sticky">
        <div className="header-middle theme1  py-lg-0 " style={{background:"#0c1355"}}>
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-6 col-lg-2 col-xl-2">
                <div className="logo ">
                  <Link to="/">
                    <img
                      src={logo}
                      alt="logo"
                      className="img-fluid webLogo w-50"
                    />
                    {}
                  </Link>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 d-none d-lg-block">
                <ul className="main-menu d-flex justify-content-center">
                  <li className=" ml-0">
                    <NavLink
                      to="/"
                      activeClassName="active"
                      className="ps-0"
                      id="linkHeader"
                    >
                      {t.home}
                    </NavLink>
                  </li>
                  {/* <li className="position-static">
                  <NavLink to="/perfume" activeClassName="active">
                    {t.product} <i className="ion-ios-arrow-down" />
                  </NavLink>
                  <ul className="mega-menu row">
                    <li className="mb-4">
                      <NavLink to="/perfume">Bütün Ətirlər</NavLink>
                    </li>
                    <hr />
                    {uniqueSexNames.slice(0, 3).reverse().map((sexName, index) => (
                      <li className="mega-menu-title col-4"  key={index}>
                        <a href="#" style={{fontWeight:"bold"}}>{sexName}</a>
                      </li>
                    ))}

                    {filteredProducts.slice(0,24).reverse().map((item) => (
                      <li className="col-4" key={item.id}>
                        <ul>
                          <li>
                            <NavLink to={`${slugify(`${item.id}-${item.name}`).toLowerCase()}`} style={{fontSize:"15px"}}>{item.name} {item.volume} ML</NavLink>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li> */}
                  <li className="position-static">
                    <NavLink
                      to="/perfume"
                      activeClassName="active"
                      id="linkHeader"
                    >
                      {t.brand} <i className="ion-ios-arrow-down" />
                    </NavLink>

                    <ul
                      className="mega-menu row"
                      style={{ height: "400px", overflowY: "scroll" }}
                    >
                      <li className="mb-4">
                        <NavLink to="/perfume" style={{ padding: "0" }}>
                          {t.allBrand}
                        </NavLink>
                      </li>
                      <hr />
                      <div className="container">
                        <div className="row">
                          {Object.keys(groupedBrands).map((letter) => (
                            <>
                              <div className="col-3">
                                <h6
                                  style={{ color: "#00AFD5" }}
                                  id="letter"
                                  className="my-3"
                                >
                                  {letter}
                                </h6>
                                {groupedBrands[letter]
                                  .filter((i) =>
                                    products.some(
                                      (product) => product.brand === i.id
                                    )
                                  )
                                  .map((item) => {
                                    const productCount = products.filter(
                                      (product) => product.brand === item.id
                                    ).length;
                                    return (
                                      <NavLink
                                        to={`/perfume/${slugify(
                                          `${item.id}-${item.bName}`
                                        ).toLowerCase()}`}
                                        className=""
                                        id="brendNav"
                                        activeClassName="active"
                                      >
                                        {item.bName} ({productCount})
                                      </NavLink>
                                    );
                                  })}
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/about"
                      id="linkHeader"
                    >
                      {t.about}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      activeClassName="active"
                      id="linkHeader"
                    >
                      {t.contact}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-lg-3 col-xl-2">
                {/* search-form end */}
                <div className="d-flex align-items-center justify-content-end">
                  {/* static-media end */}
                  <div className="cart-block-links theme1 d-none d-sm-block">
                    <ul className="d-flex">
                      {/* <li>
                    <select
                      name=""
                      id="selectLang"
                      value={language}
                      onChange={handleLanguageChange}
                    >
                      <option value="az">AZ</option>
                      <option value="en">EN</option>
                      <option value="ru">RU</option>
                    </select>
                    </li> */}
                      <li>
                        <a
                          className="offcanvas-toggle"
                          href="#"
                          onClick={() => setOpenModal(true)}
                        >
                          <span className="position-relative">
                            <i className="icon-magnifier" />
                          </span>
                        </a>

                        {modalOpen && (
                          <div
                            className={`modalContainer`}
                            onClick={() => setOpenModal(false)}
                          >
                            <div className="close">
                              <i className="icon-close" />
                            </div>
                            <div
                              className="modalContent"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {/* Modalın içində nə olacaqsa buraya əlavə et */}
                              <form onSubmit={handleSubmit}>
                                <input
                                  type="text"
                                  placeholder={t.search}
                                  onChange={(e) => setQuery(e.target.value)}
                                  value={query}
                                />
                                <button
                                  className="text-white ms-2 d-none"
                                  type="submit"
                                  style={{
                                    width: "auto",
                                    height: "35px",
                                    padding: "5px 8px",
                                    border: "1px solid #f9f9f9",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Axtar
                                </button>
                              </form>
                            </div>
                          </div>
                        )}
                      </li>

                      <li>
                        <a
                          className="offcanvas-toggle"
                          href="#"
                          onClick={() => handleOpenWish()}
                        >
                          <span className="position-relative">
                            <i className="icon-heart" />
                            <span className="badge cbdg1">
                              {" "}
                              {wish && wish.length > 0 ? wish.length : 0}
                            </span>
                          </span>
                        </a>
                      </li>
                      <li className="mr-xl-0 cart-block position-relative">
                        <a
                          className="offcanvas-toggle"
                          href="#"
                          onClick={() => handleOpenCart()}
                        >
                          <span className="position-relative">
                            <i className="icon-bag" />
                            <span className="badge cbdg1">
                              {cart && cart.length > 0 ? cart.length : 0}
                            </span>
                          </span>
                        </a>
                      </li>
                      {/* cart block end */}
                    </ul>
                  </div>
                  <div className="mobile-menu-toggle theme1 d-lg-none  d-flex align-items-center ">
                  
                        <a
                          className="offcanvas-toggle mt-2 text-white fs-4"
                          href="#"
                          onClick={() => setOpenModal(true)}
                        >
                          <span className="position-relative">
                            <i className="icon-magnifier" />
                          </span>
                        </a>

                        {modalOpen && (
                          <div
                            className={`modalContainer`}
                            onClick={() => setOpenModal(false)}
                          >
                            <div className="close">
                              <i className="icon-close" />
                            </div>
                            <div
                              className="modalContent"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {/* Modalın içində nə olacaqsa buraya əlavə et */}
                              <form onSubmit={handleSubmit}>
                                <input
                                  type="text"
                                  placeholder={t.search}
                                  onChange={(e) => setQuery(e.target.value)}
                                  value={query}
                                />
                                <button
                                  className="text-white ms-2 d-none"
                                  type="submit"
                                  style={{
                                    width: "auto",
                                    height: "35px",
                                    padding: "5px 8px",
                                    border: "1px solid #f9f9f9",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Axtar
                                </button>
                              </form>
                            </div>
                          </div>
                        )}
                     
                    <a
                      onClick={() => setActive(!active)}
                      style={{ cursor: "pointer" }}
                      className={`offcanvas-toggle ${active ? "close" : ""}`}
                    >
                      <svg viewBox="0 0 700 550">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />
                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318)"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="offcanvas-overlay"
        onClick={() => setActive(false)}
        style={{ display: `${active ? "block" : "none"}` }}
      ></div>

      <div
        id="offcanvas-mobile-menu"
        className={`offcanvas theme1 offcanvas-mobile-menu ${
          active ? "offcanvas-open" : ""
        }`}
      >
        <div className="inner">
          <div className="border-botton mb-2 d-flex  align-items-center justify-content-between">
            <div className="mobileLogo">
              {/* <img src={logomobile} alt="logo" className="img-fluid w-50" /> */}
            </div>
            <button
              className="offcanvas-close"
              onClick={() => setActive(false)}
            >
              ×
            </button>
          </div>

          <nav className="offcanvas-menu">
            <ul>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/"
                  onClick={() => setActive(false)}
                >
                  <span className="menu-text">{t.home}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/perfume"
                  onClick={() => setActive(false)}
                >
                  <span className="menu-text">{t.brand.toUpperCase()}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" onClick={() => setActive(false)}>
                  <span className="menu-text">{t.about}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/contact"
                  onClick={() => setActive(false)}
                >
                  {t.contact}
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="offcanvas-head mb-4">
            <nav className="offcanvas-top-nav  ">
              <ul className="d-flex flex-wrap flex-column">
                <li className="my-2 ">
                  <NavLink
                    activeClassName="active"
                    to="/wishlist"
                    onClick={() => setActive(false)}
                    style={{ marginLeft: "21px" }}
                  >
                    {t.wishlist}
                    <span className="ms-2">
                      ({wish && wish.length > 0 ? wish.length : 0})
                    </span>
                  </NavLink>
                </li>
                <li className="my-2 ">
                  <NavLink
                    activeClassName="active"
                    to="/cart"
                    onClick={() => setActive(false)}
                    style={{ marginLeft: "21px" }}
                  >
                    {t.cart.toUpperCase()}
                    <span>({cart && cart.length > 0 ? cart.length : 0})</span>
                  </NavLink>
                </li>
                <li className="my-2">
                  <NavLink
                    activeClassName="active"
                    to="/checkout"
                    onClick={() => setActive(false)}
                    style={{ marginLeft: "21px" }}
                  >
                    {" "}
                    {t.order.toUpperCase()}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="offcanvas-social py-30">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/myperfume.az/?igsh=cGFuZ2JmbHZ4amxj"
                  target="_blank"
                >
                  <i className="icon-social-instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="icon-social-facebook" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalWish isOpen={openWish} onToggle={handleOpenWish} />
      <ModalCart isOpen={openCart} onToggle={handleOpenCart} />
    </>
  );
};

export default Header;
