import React, { useContext, useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import { addItem } from "../../utils/addtocart";
import { addWishlist } from "../../utils/addtowishlist";
import axios from "axios";
import ApiLinkContext from "../../context/ApiLinkContext";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import { Toaster, toast } from "sonner";

import manicon from "../../assets/img/manicon.png" ;
import womanicon from "../../assets/img/womanicon.jpg" ;
import unisexicon from "../../assets/img/unisexicon.png" ; 

const ModalView = ({ data }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const { ApiLink } = useContext(ApiLinkContext);
  const [quantity, setQuantity] = useState(1);

  const [brand, setBrand] = useState([]);
  const [accord, setAccord] = useState([]);
  const [tag, setTag] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      axios.get(`${ApiLink}/products.php`),
      axios.get(`${ApiLink}/brand.php`),
      axios.get(`${ApiLink}/tag.php`),
      axios.get(`${ApiLink}/accord.php`),
    ])
      .then(([productsRes, brandsRes, tagsRes, accordsRes]) => {
        const productRes = productsRes.data;
        const brandRes = brandsRes.data;
        const tagRes = tagsRes.data;
        const accordRes = accordsRes.data;
        setTag(tagRes);
        // setProduct(productRes);
        setBrand(brandRes);
        setAccord(accordRes);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);
  const increment = () => {
    setQuantity((prevState) => (prevState += 1));
  };
  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prevState) => (prevState -= 1));
    }
  };
  const imageArray =
    data && Array.isArray(data.img) && data.img.length > 0
      ? data.img.map((image) => ({
          original: image,
          thumbnail: image,
        }))
      : [];
  const bName = brand.find((item) => item.id == data.brand);
  const aName = accord.filter(
    (item) => data && data.accord.length > 0 && data.accord.includes(item.id)
  );
  const tName = data && tag.filter(
    (item) => data && data.tag.length > 0 && data.tag.includes(item.id)
  );
  const cName =
    data.sex === "1"
      ? `${t.man}`
      : data.sex === "2"
      ? `${t.woman}`
      : data.sex === "3"
      ? `${t.unisex}`
      : "Unknown";

      const calculateDis = (price,dis) =>{
        return "-" + Math.round(((price - dis) / price) * 100);
      }
  return (
    <div className="modal fade theme1 style1" id="quick-view" role="dialog">
      <Toaster position="top-right" richColors />
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-8 mx-auto col-lg-5 mb-5 mb-lg-0">
                <div className="product-sync-init mb-20">
                  <div className="single-product">
                    <div
                      className="product-thumb"
                      style={{ position: "relative" }}
                    >
                      <ReactImageGallery
                        items={imageArray}
                        showNav={false}
                        showPlayButton={false}
                        thumbnailPosition={"bottom"}
                        additionalClass="custom-gallery"
                      />
                      <span className="badge top-right" style={{ left: "0" }}>
                        <img
                          src={`${
                            data.sex == "1"
                              ? manicon
                              : data.sex == "2"
                              ? womanicon
                              : unisexicon
                          }`}
                          alt=""
                          className="img-fluid"
                          style={{
                            position: "absolute",
                            zIndex: "1",
                            right: "0",
                            width: data.sex == "1" ? "20px" : "25px",
                          }}
                        />
                      </span>
                      <p
                        id="calculateDis"
                        className={`badge   text-center ${
                          data.dis > 0 ? "d-block" : "d-none"
                        }`}
                      >
                        <span className="mt-1" style={{fontSize:"18px"}}>{calculateDis(data.price,data.dis)}% </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="modal-product-info">
                  <div className="product-head">
                    <h2 className="title">{bName && bName.bName}  {data.name}</h2>

                    <div className="star-content mb-20">
                      <span className="star-on">
                        <i className="fas fa-star" />{" "}
                      </span>
                      <span className="star-on">
                        <i className="fas fa-star" />{" "}
                      </span>
                      <span className="star-on">
                        <i className="fas fa-star" />{" "}
                      </span>
                      <span className="star-on">
                        <i className="fas fa-star" />{" "}
                      </span>
                      <span className="star-on ">
                        <i className="fas fa-star" />
                      </span>
                    </div>
                  </div>
                  <div className="product-body">
                    <p className="product-price">
                      <del
                        className="del fs-4"
                        style={{
                          textDecoration: `${
                            data.dis > 0 ? "line-through" : "none"
                          }`,
                        }}
                      >
                        {data.price} ₼
                      </del>
                      <span
                        className={`onsale fs-4 ms-2 ${
                          data.dis > 0 ? "" : "d-none"
                        }`}
                      >
                        {data.dis} ₼
                      </span>
                    </p>
                    <p>
                      {data && language === "az"
                        ? data && data.description.slice(0, 160)
                        : language === "en"
                        ? data && data.descriptionEn.slice(0, 160)
                        : data && data.descriptionRu.slice(0, 160)}
                      ...
                    </p>
                    <br />
                    <p>
                      {t.brand1} : {bName && bName.bName}
                    </p>
                    <p className="my-1">
                      {t.accord} :{" "}
                      {aName &&
                        aName
                          .map((item) =>
                            language === "az"
                              ? item.aName
                              : language === "en"
                              ? item.aNameEn
                              : item.aNameRu
                          )
                          .join(" , ")}
                    </p>
                    <p className="my-1">
                      Not :{" "}
                      {tName &&
                        tName
                          .map((item) =>
                            language === "az"
                              ? item.tName
                              : language === "en"
                              ? item.tNameEn
                              : item.tNameRu
                          )
                          .join(" , ")}
                    </p>
                    <p>
                      {t.category} : {cName && cName}
                    </p>
                    <p className="my-1">
                      {t.volume} : {data.volume} {t.ml}
                    </p>
                  </div>
                  <div className="product-footer">
                    <div className="product-count style d-flex flex-column flex-sm-row my-4">
                      <div className="count d-flex">
                        <input
                          type="number"
                          min={1}
                          max={10}
                          step={1}
                          value={quantity}
                        />
                        <div className="button-group">
                          <button
                            className="count-btn increment"
                            onClick={() => increment()}
                          >
                            <i className="fas fa-chevron-up" />
                          </button>
                          <button
                            className="count-btn decrement"
                            onClick={() => decrement()}
                          >
                            <i className="fas fa-chevron-down" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <button
                          className="btn btn-dark btn--xl mt-5 mt-sm-0 "
                          onClick={() => addWishlist(data.id)}
                        >
                          <span className="me-2">
                            <i className="icon-heart" />
                          </span>
                          {t.wishlist}
                        </button>
                        <button
                          className="btn btn-dark btn--xl mt-5 mt-sm-0 ms-3 "
                          onClick={() => {
                            addItem(data.id, quantity);
                            toast.success(
                              `${t.cart + ":"} ${data.name}-${
                                data.volume + "ML"
                              }`,
                              { duration: 700,
                                style: {
                                  backgroundColor: '#00C2CB', 
                                  color: '#ffffff', 
                                  fontFamily:"Montserrat",
                                  border:"none"
                                }
                               }
                            );
                          }}
                        >
                          <span className="me-2">
                            <i className="ion-android-add" />
                          </span>
                          {t.addToCart}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalView;
