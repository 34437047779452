import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { EffectFade, Navigation, Autoplay, Scrollbar } from "swiper/modules";
import { NavLink, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import { ApiLinkContext } from "../../context/ApiLinkContext";
import axios from "axios";

import first from "../../assets/img/first.png";
import second from "../../assets/img/second.png";
import three from "../../assets/img/three.png";

const HomeSlider = () => {
  const { products, brand, tag, accord } = useContext(ApiLinkContext);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const result = products.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    const brandResult = brand.filter((item) =>
      item.bName.toLowerCase().includes(query.toLowerCase())
    );

    const accordResult = accord.filter((item) =>
      item.aName.toLowerCase().includes(query.toLowerCase())
    );

    const tagResult = tag.filter((item) =>
      item.tName.toLowerCase().includes(query.toLowerCase())
    );

    const data =
      result.length > 0
        ? { type: "product", items: result }
        : brandResult.length > 0
        ? { type: "brand", items: brandResult }
        : accordResult.length > 0
        ? { type: "accord", items: accordResult }
        : tagResult.length > 0
        ? { type: "tag", items: tagResult }
        : null;
    navigate("/perfume", {
      state: { data, query },
    });
  };

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        scrollbar={{ hide: false }}
        modules={[EffectFade, Navigation, Scrollbar,Autoplay]}
        className="mySwiper mySwiperHome"
      >
        <SwiperSlide>
          <NavLink to="/14-joy">
            <div className="slider_container">
              <img src={first} className="img-fluid homeBanner  w-100" />
              <div
                className="sliderTextPosition animate__animated animate__fadeInRight"
                style={{
                  top: "22%",
                  right: "15%",
                  transform: `translate(${
                    language === "az"
                      ? "-7%"
                      : language === "en"
                      ? "-2%"
                      : "-3%"
                  }, -25%)`,
                  position: "absolute",
                  textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <h1 className="text-center ">DIOR</h1>
                <p className="text-center">JOY</p>
                {/* <div className="btnExplore">
                <NavLink to="/discover">
                  <button className="text-uppercase">{t.explore}</button>
                </NavLink>
              </div> */}
              </div>
              {/* <div className="outline"></div> */}
            </div>
          </NavLink>
        </SwiperSlide>
        <SwiperSlide>
          <NavLink to="/171-erba-pura">
            <div className="slider_container">
              <img src={second} className="img-fluid homeBanner w-100" />
              <div
                className="sliderTextPosition animate__animated animate__fadeInRight"
                style={{
                  top: "25%",
                  right: "60%",
                  transform: `translate(${
                    language === "az"
                      ? "-7%"
                      : language === "en"
                      ? "-2%"
                      : "-3%"
                  }, -25%)`,
                  position: "absolute",
                  textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                 
                }}
              >
                <h1 className="text-center text-uppercase">Xerjoff</h1>
                <p className="text-center text-uppercase">Erba Pura</p>
              </div>
              {/* <div className="outline"></div> */}
            </div>
          </NavLink>
        </SwiperSlide>
        <SwiperSlide>
          <NavLink to="/34-black-musk">
            <div className="slider_container">
              <img src={three} className="img-fluid homeBanner  w-100" />
              <div
                className="sliderTextPosition animate__animated animate__fadeInRight"
                style={{
                  top: "22%",
                  right: "10%",
                  transform: `translate(${
                    language === "az"
                      ? "-7%"
                      : language === "en"
                      ? "-2%"
                      : "-3%"
                  }, -25%)`,
                  position: "absolute",
                  textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <h1 className="text-center text-uppercase">Montale </h1>
                <p className="text-uppercase text-center">Black Musk</p>
              </div>
              {/* <div className="outline"></div> */}
            </div>
          </NavLink>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HomeSlider;
