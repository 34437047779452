import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import BreadCrumb from "../utils/BreadCrumb";

const PrivacyPolicy = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <BreadCrumb title={t.terms2} />
      <section className="about-section pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-11 mx-auto mb-30">
              <div className="about-content ">
                <div>
                  <p className="mb-30 fs-6" id='termsP'  dangerouslySetInnerHTML={{__html:t.privacyDesc}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
