import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { addItem } from "../../utils/addtocart";
import { addWishlist } from "../../utils/addtowishlist";
import ModalView from "../cart/ModalView";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";

import { Toaster, toast } from "sonner";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
import man from "../../assets/img/man.jpg";
import woman from "../../assets/img/woman.jpg";

import manicon from "../../assets/img/manicon.png";
import womanicon from "../../assets/img/womanicon.jpg";
import unisexicon from "../../assets/img/unisexicon.png";
import Slider from "react-infinite-logo-slider";

const HomeManWoman = () => {
    const { products, brand } = useContext(ApiLinkContext);
    const uniqueSex = [...new Set(products.map((item) => item.sex))];
    const { language } = useContext(LanguageContext);
    const t = translations[language];
  return (
  <>
        <Slider
        width="250px"
        duration={150}
        pauseOnHover={true}
        blurBorders={true}
        blurBorderColor={"#fff"}
      >
        {brand
          .filter((i) => products.some((product) => product.brand == i.id))
          .map((item) => {
            return (
              <>
                <NavLink
                  to={`/perfume/${slugify(
                    `${item.id}-${item.bName}`
                  ).toLowerCase()}`}
                >
                  <span
                    className="mx-3  px-3 mt-3 "
                    style={{
                      // fontWeight: "550",
                      cursor: "pointer",
                      fontFamily: "Montserrat",
                      // border: "1px solid grey  ",
                      borderRadius: "5px",
                      fontSize: "20px",
                    }}
                  >
                    {item.bName.toUpperCase()}
                  </span>
                </NavLink>
              </>
            );
          })}
      </Slider>
      <marquee
        behavior=""
        scrollamount="3"
        direction=""
        className="text-white"
        style={{ backgroundColor: "#111112" }}
      ></marquee>

      <div className="container-fluid"  >
        {
          man && woman && (
            <div className="row g-3">

            </div>
          )
        }
    {man && woman && (
  <div className="row g-3">
    {uniqueSex.map((item, index) => {
      const changeSex = item === "1" ? t.man : item === "2" ? t.woman : "";
      const imageSrc = item === "1" ? man : woman;
      const urlSlug =
        item === "1"
          ? "1-perfumes-for-man"
          : item === "2"
          ? "2-perfumes-for-women"
          : "";

      // Yalnız iki elementin olduğu halda birbaşa sıralama
      return (
      <>
      
      {
        changeSex.length > 0 && (
          <div
          className={`col-lg-6 col-md-6 col-sm-12 col-12 ${
            item === "1" ? "order-1" : "order-2"
          }`}
          key={index}
        >
          <NavLink to={`/perfume/${urlSlug}`}>
            <div className="categoryContainer ">
              <div className="imgBox">
                <img src={imageSrc} alt={changeSex} className="img-fluid"  style={{height:"400px",width:"100%",objectFit:"cover", transform: item === "2" ? "scaleX(-1)" : "none",}}/>
              </div>
              <div className="animeBox">
                <div id="linkEffect" style={{left: item == "2" ? "60%" : "10%",}}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div className="titleAnime">{changeSex}</div>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
        )
      }
      </>
      );
    })}
  </div>
)}

      </div>
  
  </>
  )
}

export default HomeManWoman
