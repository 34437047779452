import React, { useContext, useState } from "react";
import HomeSlider from "../../pages/home/HomeSlider";
import OurProducts from "../../pages/home/OurProducts";
import NewArrivalProducts from "../../pages/home/NewArrivalProducts";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import ModalView from "../../pages/cart/ModalView";
import Discount from "../../pages/home/Discount";
import NewProducts from "../../pages/home/NewProducts";
import HomeManWoman from "../../pages/home/HomeManWoman";
import HomeBrand from "../../pages/home/HomeBrand";
import Parallax from "../../pages/Parallax";
import VictorySecret from "../../pages/home/VictorySecret";

const Home = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [selectedData, setSelectedData] = useState("");
  return (
    <>
      <Helmet>
        <title>MyPerfume.az | {t.original}</title>
      </Helmet>
      <HomeSlider />
      <HomeManWoman/>
      <NewProducts setSelectedData={setSelectedData}/>
      <Parallax/>
      <VictorySecret/>
      <Discount setSelectedData={setSelectedData} />
      {/* <OurProducts setSelectedData={setSelectedData} /> */}
      {/* <NewArrivalProducts setSelectedData={setSelectedData} /> */}
      <ModalView data={selectedData} />
      <HomeBrand/>
    </>
  );
};

export default Home;
