import React, { useContext, useEffect, useState } from "react";
import ModalView from "./ModalView";
import BreadCrumb from "../../utils/BreadCrumb";
import { addItem } from "../../utils/addtocart";
import { addWishlist } from "../../utils/addtowishlist";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import slugify from "react-slugify";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";
import ReactPaginate from "react-paginate";
import ReactSlider from "react-slider";
import { Helmet } from "react-helmet";

import { Toaster, toast } from "sonner";
import manicon from "../../assets/img/manicon.png";
import womanicon from "../../assets/img/womanicon.jpg";
import unisexicon from "../../assets/img/unisexicon.png";

const Accord = () => {
  const { accordId } = useParams();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { products,brand,accord, tag,loading, ApiLink } = useContext(ApiLinkContext);
  const [selectedData, setSelectedData] = useState("");



  const [volume, setVolume] = useState("");
  const [sortSelect, setSortSelect] = useState("");

  const [values, setValues] = useState([0, 0]);
  const [maxValue, setMaxValue] = useState(0);
  const [count, setCount] = useState(10);

  const [view, setView] = useState(20);
  const [search, setSearch] = useState("");
  const [searchProduct, setSearchProduct] = useState("");

  const [localWish, setLocalWish] = useState(
    () => JSON.parse(localStorage.getItem("wishlist")) || []
  );

  const location = useLocation();
  const result =
    location &&
    location.state &&
    location.state.result &&
    location.state.result;

  useEffect(() => {
    const maxPrice = Math.max(...products.map((item) => item.price));
    setMaxValue(maxPrice);
    setValues([0, maxPrice]);
  }, []);

  const [slugId, type] = accordId ? accordId.split("-") : [null, null];

  let filteredData = products.filter((item) => {
    let idMatch = true;
    if (slugId) {
      if (item && item.accord && item.accord.includes(slugId)) {
        idMatch = true;
      } else {
        idMatch = false;
      }
    }
    return idMatch;
  });

  filteredData = filteredData.filter((item) =>
    item.name.toLowerCase().includes(searchProduct.toLowerCase())
  );

  if (result && result.length > 0) {
    filteredData = filteredData.filter((item) =>
      result.some((res) => res.id === item.id)
    );
  } else {
    if (result && result.length == 0) {
      filteredData = [];
    }
  }

  if (sortSelect !== null && sortSelect == "asc") {
    filteredData.sort((a, b) => a.price - b.price);
  }
  if (sortSelect !== null && sortSelect == "desc") {
    filteredData.sort((a, b) => b.price - a.price);
  }
  if (sortSelect !== null && sortSelect == "Az") {
    filteredData.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortSelect !== null && sortSelect == "Za") {
    filteredData.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });
  }

  const ITEMS_PER_PAGE = view;
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentPageData = filteredData.slice(offset, offset + ITEMS_PER_PAGE);
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    window.scrollTo({ top: 100 });
  };

  useEffect(() => {
    const handleWishlistUpdate = () => {
      const fetchWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      setLocalWish(fetchWishlist);
    };

    window.addEventListener("wishUpdated", handleWishlistUpdate);

    // Cleanup
    return () => {
      window.removeEventListener("wishUpdated", handleWishlistUpdate);
    };
  }, []);
  let accordFindName = accord && accord.find((item) => item.id == slugId);

  const calculateDis = (price, dis) => {
    return "-" + Math.round(((price - dis) / price) * 100);
  };

  return (
    <>
      <Helmet>
        <title>{t.product}</title>
      </Helmet>
      <BreadCrumb
        title={
          accordFindName &&
          (language == "az"
            ? accordFindName.aName
            : language == "en"
            ? accordFindName.aNameEn
            : accordFindName.aNameRu)
        }
      />

      {loading ? (
        <h2 className="text-center my-5">{t.loading}</h2>
      ) : (
        <div className="product-tab bg-white pt-80 pb-50">
          <div className="container">
            <div>
              <Toaster position="top-right" richColors />
            </div>
            <div className="row">
              <div className="col-lg-12 mb-30">
                <div className="grid-nav-wraper bg-lighten2 mb-30">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                      <nav className="shop-grid-nav">
                        <ul
                          className="nav nav-pills align-items-center"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              href="#pills-home"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <i className="fa fa-th" />
                            </a>
                          </li>
                          <li className="nav-item mr-0">
                            <a
                              className="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              href="#pills-profile"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <i className="fa fa-list" />
                            </a>
                          </li>
                          <li>
                            <span
                              className="total-products text-capitalize"
                              style={{ margin: "0" }}
                            >
                              {filteredData.length} {t.result}.
                            </span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="col-12 col-md-6 position-relative">
                      <div className="shop-grid-button d-flex align-items-center">
                        <span className="sort-by"></span>
                        <select
                          name=""
                          id=""
                          className="form-select custom-select me-2"
                          aria-label="Default select example"
                          onChange={(e) => setView(e.target.value)}
                        >
                          <option value="" disabled selected>
                            {t.view} :
                          </option>
                          <option value="12">12</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                        <select
                          className="form-select custom-select"
                          aria-label="Default select example"
                          onChange={(e) => setSortSelect(e.target.value)}
                        >
                          <option selected>{t.sort}</option>
                          <option value="Az">{t.sort1}</option>
                          <option value="Za">{t.sort2}</option>
                          <option value="asc">{t.sort3}</option>
                          <option value="desc">{t.sort4}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row grid-view theme1">
                      {currentPageData.length == 0 ? (
                        <h4 className="text-start">{t.notfound}</h4>
                      ) : (
                        currentPageData.map((i) => {
                          const active = localWish.find(
                            (item) => item.id == i.id
                          );
                          return (
                            <div className="col-sm-6 col-lg-3 mb-30">
                              <div className="card product-card">
                                <div className="card-body">
                                  <div className="product-thumbnail position-relative">
                                  <p
                          id="calculateDis"
                          className={`badge    text-center ${
                            i.dis > 0 ? "d-block" : "d-none"
                          }`}
                        >
                          <span className="mt-2" >
                            {calculateDis(i.price, i.dis)}%{" "}
                          </span>
                        </p>
                                    <span
                                      className="badge top-right"
                                      style={{ left: "0" }}
                                    >
                                      <img
                                        src={`${
                                          i.sex == "1"
                                            ? manicon
                                            : i.sex == "2"
                                            ? womanicon
                                            : unisexicon
                                        }`}
                                        alt=""
                                        className="img-fluid"
                                        style={{
                                          position: "absolute",
                                          zIndex: "1",
                                          right: "0",
                                          width: i.sex == "1" ? "20px" : "25px",
                                        }}
                                      />
                                    </span>
                                    <NavLink
                                      to={`/${slugify(
                                        `${i.id}-${i.name}`
                                      ).toLowerCase()}`}
                                    >
                                      {i.img && i.img.length > 0 && (
                                        <>
                                          <img
                                            src={i.img[1]}
                                            alt="thumbnail"
                                            className="first-img img-fluid w-100"
                                          />
                                          <img
                                            src={i.img[0]}
                                            alt="thumbnail"
                                            className="second-img img-fluid w-100"
                                          />
                                        </>
                                      )}
                                    </NavLink>
                                    <ul className="actions d-flex justify-content-center">
                                      <li>
                                        <a
                                          className={`action ${
                                            active ? "activeWish" : ""
                                          }`}
                                          onClick={() => addWishlist(i.id)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span
                                            title="add to wishlist"
                                            className={`icon-heart`}
                                          ></span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="action"
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick-view"
                                          onClick={() => {
                                            setSelectedData(i);
                                          }}
                                        >
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Quick view"
                                            className="icon-magnifier"
                                          />
                                        </a>
                                      </li>
                                    </ul>
                                    {/* product links end*/}
                                  </div>
                                  <div className="product-desc py-0 px-0">
                                    <h3 className="title">
                                      <Link
                                        to={`/${slugify(
                                          `${i.id}-${i.name}`
                                        ).toLowerCase()}`}
                                      >
                                        {i.name} <br /> {i.volume} {t.ml}
                                      </Link>
                                    </h3>
                                    <div className="star-rating">
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star" />
                                      <span className="ion-ios-star de-selected" />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="product-price">
                                        <del
                                          className="del"
                                          style={{
                                            textDecoration: `${
                                              i.dis > 0
                                                ? "line-through"
                                                : "none"
                                            }`,
                                          }}
                                        >
                                          {i.price} ₼
                                        </del>
                                        <span
                                          className={`onsale ms-2 ${
                                            i.dis > 0 ? "" : "d-none"
                                          }`}
                                        >
                                          {i.dis} ₼
                                        </span>
                                      </p>
                                      <button
                                        className="pro-btn"
                                        onClick={() => {
                                          addItem(i.id);
                                          toast.success(
                                            `${t.cart + ":"} ${i.name}-${
                                              i.volume + "ML"
                                            }`,
                                            {
                                              duration: 700,
                                              style: {
                                                backgroundColor: "#00C2CB",
                                                color: "#ffffff",
                                                fontFamily: "Montserrat",
                                                border: "none",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <i className="icon-basket" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* product-list End */}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                  {/* second tab-pane */}
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row grid-view-list theme1">
                      {currentPageData.length === 0 ? (
                        <h4 className="text-start">Məhsul tapılmadı</h4>
                      ) : (
                        currentPageData.map((i) => {
                          const active = localWish.find(
                            (item) => item.id == i.id
                          );
                          return (
                            <div className="col-12 mb-30">
                              <div className="card product-card">
                                <div className="card-body">
                                  <div className="media flex-column flex-md-row">
                                    <div className="product-thumbnail position-relative">
                                      <p
                                        className={`badge badge-danger mt-2 top-center ${
                                          i.dis > 0 ? "d-block" : "d-none"
                                        }`}
                                      >
                                        {t.dis}
                                      </p>
                                      <span className="badge badge-danger top-right">
                                        {i.volume} {t.ml}
                                      </span>
                                      {/* <a href="single-product.html"> */}
                                      {i.img && i.img.length > 0 && (
                                        <>
                                          <NavLink
                                            to={`/${slugify(
                                              `${i.id}-${i.name}`
                                            ).toLowerCase()}`}
                                          >
                                            <img
                                              src={i.img[0]}
                                              alt="thumbnail"
                                              className="first-img img-fluid"
                                              style={{ width: "300px" }}
                                            />
                                            <img
                                              src={i.img[1]}
                                              alt="thumbnail"
                                              className="second-img img-fluid"
                                              style={{ width: "300px" }}
                                            />
                                          </NavLink>
                                        </>
                                      )}
                                      {/* </a> */}
                                      {/* product links */}
                                      <ul className="actions d-flex justify-content-center">
                                        <li>
                                          <a
                                            className={`action ${
                                              active ? "activeWish" : ""
                                            }`}
                                            onClick={() => {
                                              addWishlist(i.id);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-placement="bottom"
                                              title="add to wishlist"
                                              className="icon-heart"
                                            ></span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="action"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#quick-view"
                                            onClick={() => {
                                              setSelectedData(i);
                                            }}
                                          >
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-placement="bottom"
                                              title="Quick view"
                                              className="icon-magnifier"
                                            />
                                          </a>
                                        </li>
                                      </ul>
                                      {/* product links end*/}
                                    </div>
                                    <div className="media-body ps-md-4">
                                      <div className="product-desc py-0 px-0">
                                        <h3 className="title">
                                          <Link
                                            to={`/${slugify(
                                              `${i.id}-${i.name}`
                                            ).toLowerCase()}`}
                                          >
                                            {i.name}
                                          </Link>
                                        </h3>
                                        <div className="star-rating mb-10">
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star" />
                                          <span className="ion-ios-star de-selected" />
                                        </div>
                                        <p className="product-price">
                                          <del
                                            className="del"
                                            style={{
                                              textDecoration: `${
                                                i.dis > 0
                                                  ? "line-through"
                                                  : "none"
                                              }`,
                                            }}
                                          >
                                            {i.price} ₼
                                          </del>
                                          <span
                                            className={`onsale ms-2 ${
                                              i.dis > 0 ? "" : "d-none"
                                            }`}
                                          >
                                            {i.dis} ₼
                                          </span>
                                        </p>
                                      </div>
                                      <ul className="product-list-des text-secondary">
                                        <p>
                                          {i && language === "az"
                                            ? i && i.description.slice(0, 160)
                                            : language === "en"
                                            ? i && i.descriptionEn.slice(0, 160)
                                            : i &&
                                              i.descriptionRu.slice(0, 160)}
                                          ...
                                        </p>
                                      </ul>
                                      <button
                                        className="btn btn-dark btn--xl"
                                        onClick={() => {
                                          addItem(i.id);
                                          toast.success(
                                            `${t.cart + ":"} ${i.name}-${
                                              i.volume + "ML"
                                            }`,
                                            {
                                              duration: 700,
                                              style: {
                                                backgroundColor: "#00C2CB",
                                                color: "#ffffff",
                                                fontFamily: "Montserrat",
                                                border: "none",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        {t.addToCart}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* product-list End */}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-3">
                <nav className="pagination-section mt-30 mb-30">
                  <div
                    className={`react_pagination d-flex justify-content-center align-items-center mt-5 ${
                      currentPageData.length === 0 ? "d-none" : ""
                    }`}
                  >
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        filteredData.length / ITEMS_PER_PAGE
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                  {/* <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="ion-chevron-left" />
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="ion-chevron-right" />
                      </a>
                    </li>
                  </ul> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalView data={selectedData} />
    </>
  );
};

export default Accord;
